var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pro-layout",
    _vm._b(
      {
        attrs: {
          menus: _vm.menus,
          collapsed: _vm.collapsed,
          mediaQuery: _vm.query,
          isMobile: _vm.isMobile,
          handleMediaQuery: _vm.handleMediaQuery,
          handleCollapse: _vm.handleCollapse,
          i18nRender: false,
          breadcrumbRender: _vm.handleBreadcrumbRender,
          siderWidth: 210
        },
        scopedSlots: _vm._u([
          {
            key: "menuHeaderRender",
            fn: function() {
              return [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/svg/logo.svg"),
                      alt: "logo"
                    }
                  })
                ])
              ]
            },
            proxy: true
          },
          {
            key: "headerContentRender",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "ant-pro-global-header-trigger",
                    on: {
                      click: function($event) {
                        return _vm.routeReload()
                      }
                    }
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { title: "刷新页面" } },
                      [
                        _c("a-icon", {
                          staticStyle: {
                            "font-size": "18px",
                            cursor: "pointer"
                          },
                          attrs: { type: "reload" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "rightContentRender",
            fn: function() {
              return [
                _c("right-content", {
                  attrs: {
                    "top-menu": _vm.settings.layout === "topmenu",
                    "is-mobile": _vm.isMobile,
                    theme: _vm.settings.theme
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footerRender",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      },
      "pro-layout",
      _vm.settings,
      false
    ),
    [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }