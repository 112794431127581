var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["user-layout-wrapper"], attrs: { id: "userLayout" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "user-layout-lang" }),
        _c(
          "div",
          { staticClass: "user-layout-content" },
          [_vm._m(0), _c("router-view"), _c("div", { staticClass: "footer" })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "header" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "logoTitle",
            attrs: { src: require("@/assets/svg/logo.svg"), alt: "logo" }
          })
        ])
      ]),
      _c("div", { staticClass: "desc" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/svg/operate.svg"), alt: "logo" }
        }),
        _c("span", [_vm._v("商户系统")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }